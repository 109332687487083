<template>
  <div class="port">
    <div class="l-col">
      <label class="typo__label">{{ $t('label.type') }}</label>
      <base-select
        v-model="formData.port.fixed_ips[0].subnet_id"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        placeholder="Выберите подсеть"
        :custom-label="simpleSubnetName"
        :options="filtredSub"
        size="medium"
        :allow-empty="false"
        class="port-row-input"
        @input="onChange"
      >
      </base-select>
    </div>
    <div v-if="formData.port.fixed_ips[0].subnet_id && gateway_ip" class="l-col">
      <label class="typo__label">{{ $t('port.label') }}</label>
      <base-input
        v-model="gateway_ip"
        class="port-row-input"
        :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
        use-reactive-validation
        @input="onChange"
      >
      </base-input>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import networks from '@/layouts/Stack/mixins/networks';
export default {
  name: 'AttachSubnet',
  components: { BaseInput, BaseSelect },
  mixins: [setFocus, networks],
  props: {
    instance: {
      type: Object,
      default: () => {},
    },
    router: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('error') },
      },
      id: '',
      reserveIp: [],
      buferIp: '',
      useIp: [],
      ipUsed: [],
      mask: '',
      gateway_ip: [],
      listIp: [],
      pullIp: [],
      newIp: [],
      value: '',
      formData: {
        port: {
          network_id: '',
          fixed_ips: [
            {
              subnet_id: '',
              ip_address: '',
              device_id: '',
            },
          ],
        },
      },
    };
  },
  computed: {
    allPorts() {
      return this.$store.state.moduleStack.ports;
    },

    allocation_pools() {
      return this.id
        ? this.$store.state.moduleStack.subnets.find(x => x.id === this.id).allocation_pools
        : null;
    },
  },
  watch: {
    gateway_ip: function (event) {
      // console.log(event);
      this.formData.port.fixed_ips[0].ip_address = event;
      return this.$emit('change', {
        port: this.formData.port,
      });
    },
  },
  mounted() {
    this.$emit('notready');
  },

  beforeDestroy() {
    this.id = '';
  },
  methods: {
    onChange(event) {
      // console.log(event);
      if (this.subnet.includes(event)) {
        // console.log(event);
        this.gateway_ip = this.$store.state.moduleStack.subnets.find(
          x => x.id === event
        ).gateway_ip;
        // console.log(this.$store.state.moduleStack.subnets.find(x => x.id === event));
        this.formData.port.fixed_ips[0].ip_address = this.gateway_ip;
        this.id = event;
        this.formData.port.network_id = this.$store.state.moduleStack.subnets.find(
          x => x.id === event
        ).network_id;
        const port = this.$store.state.moduleStack.ports;
        const updatePort = port.map(x => x.fixed_ips.filter(a => a.subnet_id === event));
        const formatPort = updatePort.reduce((acc, item) => {
          if (item !== []) acc.push(item);
          return acc;
        }, []);
        this.listIp = formatPort
          .filter(x => x[0])
          .flat()
          .map(x => x.ip_address)
          .sort((a, b) => a.split('.').at(-1) - b.split('.').at(-1));
      } else {
        // console.log(event);
        Object.assign(this.formData, event);
        // console.log(event); //в этом моменте надо делать проверку адреса введенного, который является event
        if (
          this.formData.port.fixed_ips[0].subnet_id &&
          this.formData.port.fixed_ips[0].ip_address &&
          this.formData.port.network_id
        ) {
          return this.$emit('change', {
            port: this.formData.port,
          });
        } else return this.$emit('notready');
      }
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "disk": "Источник диска",
      "error": "Свободные порты закончились",
      "type": "Подсеть:",
      "description": "Описание"
    },
    "error": "Не верный формат",
    "port": {
      "label": "IP-адрес роутера:"
    },
    "sure": {
      "confirm": "Увеличить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.port {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
  &-input {
    margin-top: 0.5rem;
}
  }
}
</style>
